.inset-y-0 {
    top: 0px;
    bottom: 0px;
}

.min-w-\[280px\] {
    min-width: 280px;
}


.left-0 {
    left: 0px;
}

.right-0 {
    right: 0px;
}

.top-0 {
    top: 0px;
}

.z-0 {
    z-index: 0;
}

.z-10 {
    z-index: 10;
}

.mx-auto {
    margin-left: auto;
    margin-right: auto;
}

.my-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
}

.mb-4 {
    margin-bottom: 1rem;
}

.mr-2 {
    margin-right: 0.5rem;
}

.mr-2\.5 {
    margin-right: 0.625rem;
}

.flex {
    display: flex;
}

.h-4 {
    height: 1rem;
}

.h-5 {
    height: 1.25rem;
}

.h-6 {
    height: 1.5rem;
}

.h-full {
    height: 100%;
}

.max-h-\[228px\] {
    max-height: 228px;
}

.w-4 {
    width: 1rem;
}

.w-5 {
    width: 1.25rem;
}

.w-full {
    width: 100%;
}

.min-w-\[10rem\] {
    min-width: 10rem;
}

.max-w-xs {
    max-width: 20rem;
}

.flex-none {
    flex: none;
}

.cursor-default {
    cursor: default;
}

.cursor-pointer {
    cursor: pointer;
}

.items-center {
    align-items: center;
}

.justify-start {
    justify-content: flex-start;
}

.divide-y> :not([hidden])~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.overflow-y-auto {
    overflow-y: auto;
}

.truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.whitespace-nowrap {
    white-space: nowrap;
}

.border {
    border-width: 1px;
}

.bg-transparent {
    background-color: transparent;
}

.p-2 {
    padding: 0.5rem;
}

.p-2\.5 {
    padding: 0.625rem;
}

.px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.px-2\.5 {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
}

.py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}

.py-1\.5 {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
}

.py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.pl-3 {
    padding-left: 0.75rem;
}

.pr-8 {
    padding-right: 2rem;
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.font-mono {
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
}

.opacity-0 {
    opacity: 0;
}

.outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px;
}

.transition {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}

.duration-100 {
    transition-duration: 100ms;
}

.focus\:outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
}

.focus\:ring-2:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.relative {
    position: relative;
}

.top-0 {
    top: 0px;
}

.-ml-1 {
    margin-left: -0.25rem;
}

.mr-1 {
    margin-right: 0.25rem;
}

.mr-1\.5 {
    margin-right: 0.375rem;
}

.mt-5 {
    margin-top: 1.25rem;
}

.inline-flex {
    display: inline-flex;
}

.table {
    display: table;
}

.h-4 {
    height: 1rem;
}

.w-4 {
    width: 1rem;
}

.w-full {
    width: 100%;
}

.w-max {
    width: max-content;
}

.shrink-0 {
    flex-shrink: 0;
}

.cursor-default {
    cursor: default;
}

.items-center {
    align-items: center;
}

.justify-center {
    justify-content: center;
}

.divide-y> :not([hidden])~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.overflow-auto {
    overflow: auto;
}

.whitespace-nowrap {
    white-space: nowrap;
}

.bg-emerald-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(16 185 129 / var(--tw-bg-opacity));
}

.bg-opacity-10 {
    --tw-bg-opacity: 0.1;
}

.p-4 {
    padding: 1rem;
}

.p-6 {
    padding: 1.5rem;
}

.py-6 {
    padding-bottom: 1.5rem;
    padding-top: 1.5rem;
}

.px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.px-2\.5 {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
}

.px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
}

.py-0 {
    padding-top: 0px;
    padding-bottom: 0px;
}

.py-0\.5 {
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
}

.py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}

.py-3\.5 {
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
}

.text-left {
    text-align: left;
}

.align-top {
    vertical-align: top;
}

.align-middle {
    vertical-align: middle;
}

.text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
}

.font-semibold {
    font-weight: 600;
}

.text-emerald-600 {
    --tw-text-opacity: 1;
    color: rgb(5 150 105 / var(--tw-text-opacity));
}

.ring-1 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-inset {
    --tw-ring-inset: inset;
}

.ring-emerald-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(16 185 129 / var(--tw-ring-opacity));
}

.ring-opacity-20 {
    --tw-ring-opacity: 0.2;
}

.dark\:bg-emerald-500:is(.dark *) {
    --tw-bg-opacity: 1;
    background-color: rgb(16 185 129 / var(--tw-bg-opacity));
}

.dark\:bg-opacity-5:is(.dark *) {
    --tw-bg-opacity: 0.05;
}

.dark\:text-emerald-600:is(.dark *) {
    --tw-text-opacity: 1;
    color: rgb(5 150 105 / var(--tw-text-opacity));
}

.dark\:ring-emerald-500:is(.dark *) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(16 185 129 / var(--tw-ring-opacity));
}

.dark\:ring-opacity-60:is(.dark *) {
    --tw-ring-opacity: 0.6;
}

.relative {
    position: relative;
}

.-ml-1 {
    margin-left: -0.25rem;
}

.-ml-1\.5 {
    margin-left: -0.375rem;
}

.mr-5 {
    margin-right: 1.25rem;
}

.flex {
    display: flex;
}

.flex-nowrap {
    flex-wrap: nowrap;
}

.gap-x-1 {
    column-gap: 0.25rem;
}

.overflow-x-auto {
    overflow-x: auto;
    overflow-y: visible;
}

.\[scrollbar-width\:none\] {
    scrollbar-width: none;
}

.\[\&\:\:-webkit-scrollbar\]\:hidden::-webkit-scrollbar {
    display: none;
}

.line-clamp-2 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

.line-clamp-3 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
}


.inline-flex {
    display: inline-flex;
}

.w-max {
    width: max-content;
}

.flex-shrink-0 {
    flex-shrink: 0;
}

.cursor-default {
    cursor: default;
}

.items-center {
    align-items: center;
}

.justify-center {
    justify-content: center;
}

.bg-emerald-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(16 185 129 / var(--tw-bg-opacity));
}

.bg-opacity-20 {
    --tw-bg-opacity: 0.2;
}

.px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.px-2\.5 {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
}

.py-0 {
    padding-top: 0px;
    padding-bottom: 0px;
}

.py-0\.5 {
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
}

.text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
}

.text-emerald-500 {
    --tw-text-opacity: 1;
    color: rgb(16 185 129 / var(--tw-text-opacity));
}

.dark\:bg-opacity-25:is(.dark *) {
    --tw-bg-opacity: 0.25;
}

.mx-auto {
    margin-left: auto;
    margin-right: auto;
}

.my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

.flex {
    display: flex;
}

.h-\[1px\] {
    height: 1px;
}

.w-full {
    width: 100%;
}

.items-center {
    align-items: center;
}

.justify-between {
    justify-content: space-between;
}

.gap-3 {
    gap: 0.75rem;
}

.whitespace-nowrap {
    white-space: nowrap;
}

.text-inherit {
    color: inherit;
}

.relative {
    position: relative;
}

.mt-1 {
    margin-top: 0.25rem;
}

.mt-2 {
    margin-top: 0.5rem;
}

.mt-3 {
    margin-top: 0.75rem;
}

.mt-6 {
    margin-top: 1.5rem;
}

.mt-8 {
    margin-top: 2rem;
}

.flex {
    display: flex;
}

.grid {
    display: grid;
}

.w-full {
    width: 100%;
}

.items-end {
    align-items: flex-end;
}

.gap-4 {
    gap: 1rem;
}

.p-4 {
    padding: 1rem;
}

.text-left {
    text-align: left;
}

.font-semibold {
    font-weight: 600;
}

.leading-6 {
    line-height: 1.5rem;
}

.ring-1 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

@media (min-width: 640px) {
    .sm\:max-w-3xl {
        max-width: 48rem;
    }

    .sm\:grid-cols-2 {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

.bg-tremor-background {
    background-color: rgb(250 249 248);
}

.bg-tremor-border {
    border-width: 1px;
    border-color: rgb(229 231 235);
}

.dark\:bg-dark-tremor-border:is(.dark *) {
    border-color: rgb(31 41 55);
}

.text-tremor-content-strong {
    color: rgb(17 24 39);
}

.dark\:text-dark-tremor-content-strong:is(.dark *) {
    color: rgb(249 250 251);
}

.ring-tremor-ring {
    --tw-ring-color: rgb(229 231 235 / var(--tw-ring-opacity));
}

.dark\:ring-dark-tremor-ring:is(.dark *) {
    --tw-ring-color: rgb(31 41 55 / var(--tw-ring-opacity));
}

.shadow-tremor-card {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px -1px rgba(0, 0, 0, .1);
    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
}

.dark\:shadow-dark-tremor-card:is(.dark *) {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px -1px rgba(0, 0, 0, .1);
    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.mt-6 {
    margin-top: 1.5rem;
}

.grid {
    display: grid;
}

.gap-4 {
    gap: 1rem;
}

@media (min-width: 640px) {
    .sm\:max-w-3xl {
        max-width: 48rem;
    }

    .sm\:grid-cols-2 {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

.bg-white\/60 {
    background-color: rgb(255 255 255 / 0.6);
}

.bg-white\/80 {
    background-color: rgb(255 255 255 / 0.8);
}

.bg-white\/90 {
    background-color: rgb(255 255 255 / 0.9);
}

.backdrop-blur-lg {
    --tw-backdrop-blur: blur(16px);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.dark\:bg-black\/60:is(.dark *) {
    background-color: rgb(0 0 0 / 0.6);
}

.dark\:bg-black\/10:is(.dark *) {
    background-color: rgb(0 0 0 / 0.1);
}

.dark\:bg-teal-950\/50:is(.dark *) {
    background-color: rgb(4 47 46 / 0.5);
}

.dark\:bg-teal-950\/10:is(.dark *) {
    background-color: rgb(4 47 46 / 0.1);
}

.absolute {
    position: absolute;
}

.relative {
    position: relative;
}

.inset-y-0 {
    top: 0px;
    bottom: 0px;
}

.left-0 {
    left: 0px;
}

.right-0 {
    right: 0px;
}

.top-0 {
    top: 0px;
}

.z-0 {
    z-index: 0;
}

.mr-3 {
    margin-right: 0.75rem;
}

.mt-6 {
    margin-top: 1.5rem;
}

.block {
    display: block;
}

.flex {
    display: flex;
}

.grid {
    display: grid;
}

.h-48 {
    height: 12rem;
}

.h-5 {
    height: 1.25rem;
}

.h-full {
    height: 100%;
}

.w-5 {
    width: 1.25rem;
}

.w-\[90\%\] {
    width: 90%;
}

.w-full {
    width: 100%;
}

.min-w-\[10rem\] {
    min-width: 10rem;
}

.flex-none {
    flex: none;
}

.items-start {
    align-items: flex-start;
}

.items-center {
    align-items: center;
}

.justify-end {
    justify-content: flex-end;
}

.gap-4 {
    gap: 1rem;
}

.truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.whitespace-nowrap {
    white-space: nowrap;
}

.border {
    border-width: 1px;
}

.p-6 {
    padding: 1.5rem;
}

.py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.pl-3 {
    padding-left: 0.75rem;
}

.pr-8 {
    padding-right: 2rem;
}

.text-left {
    text-align: left;
}

.text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
}

.font-semibold {
    font-weight: 600;
}

.opacity-0 {
    opacity: 0;
}

.outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px;
}

.ring-1 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.transition {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}

.duration-100 {
    transition-duration: 100ms;
}

.focus\:ring-2:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

@media (min-width: 640px) {
    .sm\:mx-auto {
        margin-left: auto;
        margin-right: auto;
    }

    .sm\:w-48 {
        width: 12rem;
    }

    .sm\:w-96 {
        width: 24rem;
    }

    .sm\:max-w-3xl {
        max-width: 48rem;
    }

    .sm\:max-w-lg {
        max-width: 32rem;
    }

    .sm\:grid-cols-2 {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

.dark\:bg-\[\#121619\]\/80:is(.dark *) {
    background-color: rgb(18 22 25 / 0.8);
}

.gap-x-2 {
    column-gap: 0.5rem;
}

.max-w-screen-2xl {
    max-width: 1536px;
}

.mt-2 {
    margin-top: 0.5rem;
}

.flex {
    display: flex;
}

.inline-flex {
    display: inline-flex;
}

.h-10 {
    height: 2.5rem;
}

.h-2 {
    height: 0.5rem;
}

.h-2\.5 {
    height: 0.625rem;
}

.h-full {
    height: 100%;
}

.w-2 {
    width: 0.5rem;
}

.w-2\.5 {
    width: 0.625rem;
}

.w-full {
    width: 100%;
}

.flex-shrink-0 {
    flex-shrink: 0;
}

.shrink-0 {
    flex-shrink: 0;
}

.items-start {
    align-items: flex-start;
}

.items-center {
    align-items: center;
}

.justify-end {
    justify-content: flex-end;
}

.justify-center {
    justify-content: center;
}

.justify-between {
    justify-content: space-between;
}

.gap-x-2 {
    column-gap: 0.5rem;
}

.space-x-0> :not([hidden])~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-0\.5> :not([hidden])~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.125rem * var(--tw-space-x-reverse));
    margin-left: calc(0.125rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-2> :not([hidden])~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-2\.5> :not([hidden])~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.625rem * var(--tw-space-x-reverse));
    margin-left: calc(0.625rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-6> :not([hidden])~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.divide-y> :not([hidden])~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.rounded-\[1px\] {
    border-radius: 1px;
}

.rounded-sm {
    border-radius: 0.125rem;
}

.border {
    border-width: 1px;
}

.bg-emerald-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(16 185 129 / var(--tw-bg-opacity));
}

.bg-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(107 114 128 / var(--tw-bg-opacity));
}

.bg-rose-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(244 63 94 / var(--tw-bg-opacity));
}

.bg-teal-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(15 118 110 / var(--tw-bg-opacity));
}

.bg-yellow-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(234 179 8 / var(--tw-bg-opacity));
}

.px-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
}

.px-1\.5 {
    padding-left: 0.375rem;
    padding-right: 0.375rem;
}

.px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
}

.py-0 {
    padding-top: 0px;
    padding-bottom: 0px;
}

.py-0\.5 {
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
}

.py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
}

.font-medium {
    font-weight: 500;
}

.tabular-nums {
    --tw-numeric-spacing: tabular-nums;
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
}

.text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}

.outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px;
}

.first\:rounded-l-\[4px\]:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.last\:rounded-r-\[4px\]:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.dark\:bg-teal-600:is(.dark *) {
    --tw-bg-opacity: 1;
    background-color: rgb(13 148 136 / var(--tw-bg-opacity));
}

.w-16 {
    width: 4rem;
}

.-mb-2 {
    margin-bottom: -0.5rem;
}

.transition-none {
    transition-property: none;
}

.duration-500 {
    transition-duration: 500ms;
}

.ease-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}

.h-5 {
    height: 1.25rem;
}

.w-5 {
    width: 1.25rem;
}

.relative {
    position: relative;
}

.mt-1 {
    margin-top: 0.25rem;
}

.mt-2 {
    margin-top: 0.5rem;
}

.mt-4 {
    margin-top: 1rem;
}

.block {
    display: block;
}

.flex {
    display: flex;
}

.w-full {
    width: 100%;
}

.min-w-\[10rem\] {
    min-width: 10rem;
}

.cursor-pointer {
    cursor: pointer;
}

.appearance-none {
    appearance: none;
}

.items-center {
    align-items: center;
}

.justify-end {
    justify-content: flex-end;
}

.space-x-3> :not([hidden])~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-6> :not([hidden])~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.whitespace-nowrap {
    white-space: nowrap;
}

.border {
    border-width: 1px;
}

.border-none {
    border-style: none;
}

.bg-transparent {
    background-color: transparent;
}

.px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.px-2\.5 {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
}

.px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
}

.py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}

.py-1\.5 {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
}

.py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.pl-3 {
    padding-left: 0.75rem;
}

.pr-4 {
    padding-right: 1rem;
}

.font-medium {
    font-weight: 500;
}

.font-semibold {
    font-weight: 600;
}

.leading-6 {
    line-height: 1.5rem;
}

.text-red-500 {
    --tw-text-opacity: 1;
    color: rgb(239 68 68 / var(--tw-text-opacity));
}

.outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px;
}

.transition {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}

.transition-all {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}

.duration-100 {
    transition-duration: 100ms;
}

.\[appearance\:textfield\] {
    appearance: textfield;
}

.file\:-my-1::file-selector-button {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
}

.file\:-my-1\.5::file-selector-button {
    margin-top: -0.375rem;
    margin-bottom: -0.375rem;
}

.file\:-ml-2::file-selector-button {
    margin-left: -0.5rem;
}

.file\:-ml-2\.5::file-selector-button {
    margin-left: -0.625rem;
}

.file\:h-\[36px\]::file-selector-button {
    height: 36px;
}

.file\:cursor-pointer::file-selector-button {
    cursor: pointer;
}

.file\:border-0::file-selector-button {
    border-width: 0px;
}

.file\:px-3::file-selector-button {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}

.file\:py-1::file-selector-button {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}

.file\:py-1\.5::file-selector-button {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
}

.file\:outline-none::file-selector-button {
    outline: 2px solid transparent;
    outline-offset: 2px;
}

.file\:\[margin-inline-end\:0\.75rem\]::file-selector-button {
    margin-inline-end: 0.75rem;
}

.focus\:outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
}

.focus\:ring-0:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.file\:disabled\:pointer-events-none:disabled::file-selector-button {
    pointer-events: none;
}

.file\:disabled\:dark\:border-gray-700:is(.dark *):disabled::file-selector-button {
    --tw-border-opacity: 1;
    border-color: rgb(55 65 81 / var(--tw-border-opacity));
}

@media (min-width: 640px) {
    .sm\:mx-auto {
        margin-left: auto;
        margin-right: auto;
    }

    .sm\:max-w-lg {
        max-width: 32rem;
    }
}

.inline-flex {
    display: inline-flex;
}

.w-max {
    width: max-content;
}

.flex-shrink-0 {
    flex-shrink: 0;
}

.cursor-default {
    cursor: default;
}

.items-center {
    align-items: center;
}

.justify-center {
    justify-content: center;
}

.px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.px-2\.5 {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
}

.py-0 {
    padding-top: 0px;
    padding-bottom: 0px;
}

.py-0\.5 {
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
}

.text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
}

.gap-y-2 {
    row-gap: .5rem;
}

.gap-x-6 {
    column-gap: 1.5rem;
}

@media (min-width: 1536px) {
    .\3xl\:grid-cols-3 {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}

@media (min-width: 1280px) {
    .xl\:grid-cols-3 {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    .xl\:flex-row {
        flex-direction: row;
    }
}

.overflow-auto {
    overflow: auto;
}

@media (min-width: 1024px) {
    .lg\:grid-cols-3 {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}


.filter {
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}


.table {
    display: table;
}

.\!h-6 {
    height: 1.5rem !important;
}

.h-4 {
    height: 1rem;
}

.\!w-6 {
    width: 1.5rem !important;
}

.w-4 {
    width: 1rem;
}

.max-w-md {
    max-width: 28rem;
}

.cursor-pointer {
    cursor: pointer;
}

.select-none {
    user-select: none;
}

.whitespace-normal {
    white-space: normal;
}

.px-0 {
    padding-left: 0px;
    padding-right: 0px;
}

.px-0\.5 {
    padding-left: 0.125rem;
    padding-right: 0.125rem;
}

.px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}

.py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}

.py-1\.5 {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
}

.h-40 {
    height: 10rem;
}


.w-2 {
    width: 0.5rem;
}

.w-2\.5 {
    width: 0.625rem;
}

.space-x-2> :not([hidden])~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-2\.5> :not([hidden])~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.625rem * var(--tw-space-x-reverse));
    margin-left: calc(0.625rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-6> :not([hidden])~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.divide-y> :not([hidden])~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.py-0 {
    padding-top: 0px;
    padding-bottom: 0px;
}

.py-0\.5 {
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
}

.fill-tremor-content-emphasis {
    fill: #374151;
}

.dark\:fill-dark-tremor-content-emphasis:is(.dark *) {
    fill: #e5e7eb;
}

.tabular-nums {
    --tw-numeric-spacing: tabular-nums;
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
}

.ring-1 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

@media (min-width: 640px) {
    .sm\:mx-auto {
        margin-left: auto;
        margin-right: auto;
    }

    .sm\:max-w-lg {
        max-width: 32rem;
    }
}

.border-b {
    border-bottom-width: 1px;
}

.bg-gradient-to-t {
    background-image: linear-gradient(to top, var(--tw-gradient-stops));
}

.to-transparent {
    --tw-gradient-to: transparent var(--tw-gradient-to-position);
}

.max-h-\[260px\] {
    max-height: 260px;
}

.inset-x-0 {
    left: 0px;
    right: 0px;
}

.from-inherit {
    --tw-gradient-from: inherit var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(255 255 255 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.bottom-0 {
    bottom: 0px;
}

.py-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
}

.from-white\/80 {
    --tw-gradient-from: rgb(255 255 255 / 0.8) var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(255 255 255 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.dark\:from-black\/40:is(.dark *) {
    --tw-gradient-from: rgb(0 0 0 / 0.4) var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(0 0 0 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.bg-opacity-20 {
    --tw-bg-opacity: 0.2;
}

@media (min-width: 1024px) {
    .lg\:max-w-lg {
        max-width: 32rem;
    }

    .lg\:flex-row {
        flex-direction: row;
    }
}

/*popover-control s*/
.size-6 {
    width: 1.5rem;
    height: 1.5rem;
}

.cursor-pointer {
    cursor: pointer;
}

.rounded-lg {
    border-radius: 0.5rem;
}

.bg-white\/10 {
    background-color: rgb(255 255 255 / 0.1);
}

.bg-white\/5 {
    background-color: rgb(255 255 255 / 0.05);
}

.fill-white {
    fill: #fff;
}

.px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
}

.py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.text-sm\/6 {
    font-size: 0.875rem;
    line-height: 1.5rem;
}

.text-white\/50 {
    color: rgb(255 255 255 / 0.5);
}

.opacity-0 {
    opacity: 0;
}

.opacity-100 {
    opacity: 1;
}

.shadow-md {
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px;
}

.outline-1 {
    outline-width: 1px;
}

.outline-white {
    outline-color: #fff;
}

.transition {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}

.focus\:outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
}

.visible {
    visibility: visible;
}

.invisible {
    visibility: hidden;
}

.size-2 {
    width: 0.5rem;
    height: 0.5rem;
}

.size-5 {
    width: 1.25rem;
    height: 1.25rem;
}

.rounded-full {
    border-radius: 9999px;
}

.bg-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(96 165 250 / var(--tw-bg-opacity));
}

.bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.ml-auto {
    margin-left: auto;
}

.inline {
    display: inline;
}

.size-4 {
    width: 1rem;
    height: 1rem;
}

.w-52 {
    width: 13rem;
}

.origin-top-right {
    transform-origin: top right;
}

.scale-95 {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.border-white\/5 {
    border-color: rgb(255 255 255 / 0.05);
}

.bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}

.bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}

.bg-white\/10 {
    background-color: rgb(255 255 255 / 0.1);
}

.dark\:.bg-white\/5:is(.dark *) {
    background-color: rgb(255 255 255 / 0.05);
}

.fill-white\/30 {
    fill: rgb(255 255 255 / 0.3);
}

.fill-white\/60 {
    fill: rgb(255 255 255 / 0.6);
}

.px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}

.py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}

.py-1\.5 {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
}

.text-sm\/6 {
    font-size: 0.875rem;
    line-height: 1.5rem;
}

.dark\:.text-white\/50:is(.dark *) {
    color: rgb(255 255 255 / 0.5);
}

.opacity-0 {
    opacity: 0;
}

.shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 rgb(0 0 0 / 0.05);
    --tw-shadow-colored: inset 0 2px 4px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-white\/10 {
    --tw-shadow-color: rgb(255 255 255 / 0.1);
    --tw-shadow: var(--tw-shadow-colored);
}

.outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px;
}

.outline-1 {
    outline-width: 1px;
}

.outline-white {
    outline-color: #fff;
}

.transition {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}

.duration-100 {
    transition-duration: 100ms;
}

.ease-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}

.\[--anchor-gap\:var\(--spacing-1\)\] {
    --anchor-gap: var(--spacing-1);
}

.focus\:outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
}

.group[data-checked] .group-checked\:opacity-100 {
    opacity: 1;
}

.data-\[hover\]\:bg-white\/10[data-hover] {
    background-color: rgb(255 255 255 / 0.1);
}

.data-\[checked\]\:bg-white\/20[data-checked] {
    background-color: rgb(255 255 255 / 0.2);
}

/*popover-control e*/
.-me-2 {
    margin-inline-end: -0.5rem;
}

.-me-2\.5 {
    margin-inline-end: -0.625rem;
}

.size-4 {
    width: 1rem;
    height: 1rem;
}

.size-5 {
    width: 1.25rem;
    height: 1.25rem;
}

.size-8 {
    width: 2rem;
    height: 2rem;
}

.h-2 {
    height: 0.5rem;
}

.gap-x-1 {
    column-gap: 0.25rem;
}

.gap-x-1\.5 {
    column-gap: 0.375rem;
}

.gap-x-2 {
    column-gap: 0.5rem;
}

.gap-x-3 {
    column-gap: 0.75rem;
}

.space-y-7> :not([hidden])~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.75rem * var(--tw-space-y-reverse));
}

.rounded-lg {
    border-radius: 0.5rem;
}

.rounded-xl {
    border-radius: 0.75rem;
}

.rounded-b-xl {
    border-bottom-right-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
}

.border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.border-neutral-700 {
    --tw-border-opacity: 1;
    border-color: rgb(64 64 64 / var(--tw-border-opacity));
}

.border-transparent {
    border-color: transparent;
}

.bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.bg-neutral-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(64 64 64 / var(--tw-bg-opacity));
}

.bg-neutral-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(38 38 38 / var(--tw-bg-opacity));
}

.bg-white\/10 {
    background-color: rgb(255 255 255 / 0.1);
}

.p-5 {
    padding: 1.25rem;
}

.px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}

.px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
}

.px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
}

.py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
}

.text-gray-500 {
    --tw-text-opacity: 1;
    color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-gray-800 {
    --tw-text-opacity: 1;
    color: rgb(31 41 55 / var(--tw-text-opacity));
}

.text-neutral-200 {
    --tw-text-opacity: 1;
    color: rgb(229 229 229 / var(--tw-text-opacity));
}

.text-neutral-400 {
    --tw-text-opacity: 1;
    color: rgb(163 163 163 / var(--tw-text-opacity));
}

.text-neutral-500 {
    --tw-text-opacity: 1;
    color: rgb(115 115 115 / var(--tw-text-opacity));
}

.text-teal-500 {
    --tw-text-opacity: 1;
    color: rgb(20 184 166 / var(--tw-text-opacity));
}

.text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}

.opacity-50 {
    opacity: 0.5;
}

.shadow-sm {
    --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.transition {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}

.duration-500 {
    transition-duration: 500ms;
}

.hover\:bg-gray-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.hover\:text-gray-800:hover {
    --tw-text-opacity: 1;
    color: rgb(31 41 55 / var(--tw-text-opacity));
}

.disabled\:pointer-events-none:disabled {
    pointer-events: none;
}

.disabled\:opacity-50:disabled {
    opacity: 0.5;
}

@media (min-width: 768px) {
    .md\:p-5 {
        padding: 1.25rem;
    }

    .md\:px-5 {
        padding-left: 1.25rem;
        padding-right: 1.25rem;
    }
}

.dark\:bg-cyan-800:where(.dark, .dark *) {
    --tw-bg-opacity: 1;
    background-color: rgb(21 94 117 / var(--tw-bg-opacity));
}

.dark\:bg-cyan-900:where(.dark, .dark *) {
    --tw-bg-opacity: 1;
    background-color: rgb(22 78 99 / var(--tw-bg-opacity));
}

.dark\:border-neutral-700:where(.dark, .dark *) {
    --tw-border-opacity: 1;
    border-color: rgb(64 64 64 / var(--tw-border-opacity));
}

.dark\:bg-neutral-700:where(.dark, .dark *) {
    --tw-bg-opacity: 1;
    background-color: rgb(64 64 64 / var(--tw-bg-opacity));
}

.dark\:bg-neutral-800:where(.dark, .dark *) {
    --tw-bg-opacity: 1;
    background-color: rgb(38 38 38 / var(--tw-bg-opacity));
}

.dark\:bg-white\/10:where(.dark, .dark *) {
    background-color: rgb(255 255 255 / 0.1);
}

.dark\:text-neutral-400:where(.dark, .dark *) {
    --tw-text-opacity: 1;
    color: rgb(163 163 163 / var(--tw-text-opacity));
}

.dark\:text-neutral-500:where(.dark, .dark *) {
    --tw-text-opacity: 1;
    color: rgb(115 115 115 / var(--tw-text-opacity));
}

.dark\:text-white:where(.dark, .dark *) {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}

.dark\:hover\:bg-neutral-800:hover:where(.dark, .dark *) {
    --tw-bg-opacity: 1;
    background-color: rgb(38 38 38 / var(--tw-bg-opacity));
}

.dark\:hover\:text-neutral-200:hover:where(.dark, .dark *) {
    --tw-text-opacity: 1;
    color: rgb(229 229 229 / var(--tw-text-opacity));
}

.dark\:hover\:text-white:hover:where(.dark, .dark *) {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}

/**/
.pointer-events-none {
    pointer-events: none;
}

.left-2 {
    left: 0.5rem;
}

.z-50 {
    z-index: 50;
}

.-mx-1 {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
}

.my-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
}

.ml-2 {
    margin-left: 0.5rem;
}

.size-4 {
    width: 1rem;
    height: 1rem;
}

.h-2 {
    height: 0.5rem;
}

.h-3 {
    height: 0.75rem;
}

.h-3\.5 {
    height: 0.875rem;
}

.h-4 {
    height: 1rem;
}

.h-px {
    height: 1px;
}

.w-2 {
    width: 0.5rem;
}

.w-3 {
    width: 0.75rem;
}

.w-3\.5 {
    width: 0.875rem;
}

.w-4 {
    width: 1rem;
}

.min-w-\[10rem\] {
    min-width: 10rem;
}

.min-w-\[8rem\] {
    min-width: 8rem;
}

.max-w-sm {
    max-width: 24rem;
}

.cursor-default {
    cursor: default;
}

.select-none {
    -webkit-user-select: none;
    user-select: none;
}

.appearance-none {
    appearance: none;
}

.overflow-hidden {
    overflow: hidden;
}

.rounded {
    border-radius: 0.25rem;
}

.rounded-md {
    border-radius: 0.375rem;
}

.rounded-sm {
    border-radius: 0.125rem;
}

.border-none {
    border-style: none;
}

.bg-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(59 130 246 / var(--tw-bg-opacity));
}

.bg-transparent {
    background-color: transparent;
}

.bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.fill-current {
    fill: currentColor;
}

.stroke-white {
    stroke: #fff;
}

.p-1 {
    padding: 0.25rem;
}

.px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}

.py-1\.5 {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
}

.py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.pl-3 {
    padding-left: 0.75rem;
}

.pl-8 {
    padding-left: 2rem;
}

.pr-2 {
    padding-right: 0.5rem;
}

.pr-4 {
    padding-right: 1rem;
}

.tracking-widest {
    letter-spacing: 0.1em;
}

.opacity-0 {
    opacity: 0;
}

.opacity-100 {
    opacity: 1;
}

.opacity-50 {
    opacity: 0.5;
}

.opacity-60 {
    opacity: 0.6;
}

.shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px;
}

.ring-0 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.transition {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}

.transition-colors {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}

.duration-100 {
    transition-duration: 100ms;
}

.\[appearance\:textfield\] {
    appearance: textfield;
}

.focus\:outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
}

.focus\:ring-0:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.data-\[checked\]\:bg-blue-500[data-checked] {
    --tw-bg-opacity: 1;
    background-color: rgb(59 130 246 / var(--tw-bg-opacity));
}

.group[data-checked] .group-data-\[checked\]\:opacity-100 {
    opacity: 1;
}

.\[\&\:\:-webkit-inner-spin-button\]\:appearance-none::-webkit-inner-spin-button {
    appearance: none;
}

.\[\&\:\:-webkit-outer-spin-button\]\:appearance-none::-webkit-outer-spin-button {
    appearance: none;
}

.overflow-scroll-y {
    overflow-y: scroll;
    overflow-x: visible;
}

.whitespace-pre-line {
    white-space: pre-line;
}

.-mr-6 {
    margin-right: -1.5rem;
}

.right-10 {
    right: 2.5rem;
}

.\!rounded-none {
    border-radius: 0px !important;
}

.active-scroll-spy {
    color: rgb(20 184 166);
}

.stroke-blue-200 {
    stroke: #bfdbfe;
}

.stroke-blue-500\/30 {
    stroke: rgb(59 130 246 / 0.3);
}

.transition-colors {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}

.ease-linear {
    transition-timing-function: linear;
}

.stroke-tremor-brand-muted\/50 {
    stroke: rgba(191, 219, 254, .5);
}

.dark\:stroke-dark-tremor-brand-muted:is(.dark *) {
    stroke: #172554;
}

.stroke-tremor-brand {
    stroke: #3b82f6;
}

.text-gray-900 {
    --tw-text-opacity: 1;
    color: rgb(17 24 39 / var(--tw-text-opacity));
}

.text-gray-500 {
    --tw-text-opacity: 1;
    color: rgb(107 114 128 / var(--tw-text-opacity));
}

.dark\:text-gray-50:where(.dark, .dark *) {
    --tw-text-opacity: 1;
    color: rgb(249 250 251 / var(--tw-text-opacity));
}

.dark\:text-gray-500:where(.dark, .dark *) {
    --tw-text-opacity: 1;
    color: rgb(107 114 128 / var(--tw-text-opacity));
}

.dark\:stroke-blue-500\/30:is(.dark *) {
    stroke: rgb(59 130 246 / 0.3);
}

.dark\:stroke-dark-tremor-brand:is(.dark *) {
    stroke: #3b82f6;
}

.transform {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-rotate-90,
.rotate-180 {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-rotate-90 {
    --tw-rotate: -90deg;
}

.ring-tremor-ring {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(229 231 235/var(--tw-ring-opacity));
}

.shadow-tremor-card {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px -1px rgba(0, 0, 0, .1);
    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
}

.shadow-sm,
.shadow-tremor-card {
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.bg-tremor-background {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.dark\:ring-dark-tremor-ring:is(.dark *) {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(31 41 55/var(--tw-ring-opacity));
}

.dark\:shadow-dark-tremor-card:is(.dark *) {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px -1px rgba(0, 0, 0, .1);
    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

/*
.dark\:bg-dark-tremor-background:is(.dark *) {
    --tw-bg-opacity: 1;
    background-color: rgb(12 28 36 / var(--tw-bg-opacity));
}
*/