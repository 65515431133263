body {
    font-family: Arial, sans-serif;
    background-color: black;
    /* Sfondo nero per il corpo del documento */
    color: white;
    /* Testo bianco per contrasto */
}

.links line {
    stroke: #999;
    stroke-opacity: 0.3;
    stroke-width: 0.5px;
}

.nodes circle {
    stroke: #fff;
    stroke-width: 1.5px;
    z-index: 0;
}

.label {
    background: #000
}

.tooltip {
    background-color: #000;
    border: 1px solid #ddd;
    padding: 5px 10px;
    position: absolute;
    display: none;
    pointer-events: none;
    z-index: 10;
}