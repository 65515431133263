/*
 * CSS for the HTML page
 */
/*
body {
  margin: 0;
  padding: 0;
  background: #f0f0f0;
  color: #333;
  font-family: Ubuntu, sans-serif;
  line-height: 1.5;
}

* {
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
}

p a {
  color: #5a4ed9;
}

iframe {
  display: block;
  width: 100%;
  height: 100%;
  border: none;
  float: left;
}

#cHeader_navigation {
  font-size: 0.8em;
  color: #666;
  margin: -0.3em 0 0.3em;
}

h1 {
  margin: 0 0 0.5em;
  padding: 0;
  float: left;
  text-transform: uppercase;
  font-size: 1.5em;
  letter-spacing: 0.08em;
  font-weight: normal;
}

.header {
  background: white;
  padding: 1em 1em 0;
  width: 100%;
  overflow: auto;
}

.content {
  width: 100%;
  height: 100vh;
  clear: both;
}

.large {
  font-size: 1.15em;
}

.content p {
  float: left;
  max-width: 32em;
  margin: 2em 1em 0;
}

.teaser p {
  margin-top: 0;
}

.content p strong {
  font-weight: bold;
  letter-spacing: 0.02em;
  margin-right: 0.3em;
}

.menu {
  display: block;
  float: right;
  padding: 0;
  margin: 0.6em -0.6em 0 0;
}

@media (max-width: 960px) {
  h1 {
    float: none;
  }

  .menu {
    float: left;
    margin: 0 0 0 -0.6em;
  }
}

.menu.teaser {
  margin: 2em 0;
  display: grid;
  grid-auto-rows: auto;
  grid-row-gap: 1em;
  grid-auto-columns: 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "image1 image2 text1"
    "image1 image2 text2"
    "image3 image4 text3";

  width: 100%;
}

@media (max-width: 1280px) {
  .menu.teaser {
    grid-template-areas:
      "text1 text2"
      "image1 image2"
      "image3 image4"
      "text3 .";
  }
}

@media (max-width: 960px) {
  .menu.teaser {
    grid-template-areas:
      "text1"
      "image1" "image2"
      "text2"
      "image3" "image4"
      "text3";
  }
}

.image1 {
  grid-area: image1;
}
.image2 {
  grid-area: image2;
}
.image3 {
  grid-area: image3;
}
.image4 {
  grid-area: image4;
}
.text1 {
  grid-area: text1;
}
.text2 {
  grid-area: text2;
}
.text3 {
  grid-area: text3;
}
/* 
@media (max-width: 1400px) {
  .menu.teaser {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 960px) {
  .menu.teaser {
    grid-template-columns: 1fr;
  }
}

.menu li {
  list-style: none;
  display: block;
  padding: 0 0.9em;
  float: left;
}

.menu li img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.menu li span {
  display: block;
  width: 100%;
  height: auto;
  padding: 0.3em 0 1em 0;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-size: 0.8em;
}

.menu li a {
  display: inline-block;
  width: 100%;
}

.active {
  font-weight: bold;
  border-bottom: 3px solid #555;
}

.content.grid {
  display: grid;
  grid-template-areas: "vis vis vis vis" ". info-text-1 info-text-2 .";
  grid-template-columns: 1fr auto auto 1fr;
  grid-template-rows: minmax(800px, 1fr) auto;
  width: auto;
  height: auto;
}

@media (min-width: 1400px) and (min-height: 800px) {
  .content.grid {
    grid-template-areas:
      "vis info-text-1"
      "vis info-text-2" "vis .";
    grid-template-columns: 1fr auto;
    grid-template-rows: auto auto minmax(800px, 1fr);
    position: absolute;
    top: 85px;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

@media (max-width: 920px) {
  .content.grid {
    grid-template-areas:
      "vis" "info-text-1"
      "info-text-2";
    grid-template-columns: 1fr;
  }
}

.content.grid .info-text-1 {
  grid-area: info-text-1;
}

.content.grid .info-text-2 {
  grid-area: info-text-2;
}

.content.grid .vis {
  grid-area: vis;
  min-height: 100vh;
  min-height: calc(100vh-80px);
}

*/
button#fullscreen {
  position: absolute;
  bottom: 24px;
  top: auto !important;
  right: 24px;

  border: 0;

  text-decoration: none;
  border-radius: 5px;
  /*background-color: #459FE2;*/
  background-color: #1C7170;
  /*color: #FCFCFD;*/
  color: #ffffff;
  height: 32px;
  padding: 4px 12px;
  font-size: 16px;
  font-weight: 400;
  text-transform: none;
  min-height: 32px;
  display: inline-flex;
  align-items: center;
}

button#fullscreen:before {
  background-image: url('../../images/fullscreen.svg');
  content: "";
  display: inline-block;
  margin-right: 12px;
  width: 18px;
  height: 18px;
  background-size: contain;
  background-position: 50% 50%;
}