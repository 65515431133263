.waffle-chart-container {
    /*
    --size-min: 5px;
    */
    --size-min: 4px;
    --size-max: 50px;
    /*
    --gap: 8px;
    */
    --gap: 1px;
    --bg-fallback-default: cadetblue;
    --bg-fallback-a: palevioletred;
    --bg-fallback-b: steelblue;
    --bg-fallback-c: slategray;
    --bg-fallback-total: seagreen;
    /*
    --radius-square: 1px;
    */
    --radius-square: 0;
    --font-label: clamp(2.5rem, 8vw, 120px);
    --font-data: clamp(1.1rem, 3vw, 36px);
}

.waffle-chart-container {
    display: flex;
    width: fit-content;
}

.a {
    color: var(--bg-fallback-a);
}

.b {
    color: var(--bg-fallback-b);
}

.c {
    color: var(--bg-fallback-c);
}

.waffle-chart {
    top: 0;
    left: 0;
    display: grid;
    grid-template-columns: repeat(10, minmax(var(--size-min), var(--size-max)));
    grid-gap: var(--gap);
    margin: var(--gap);
    box-sizing: border-box;
}

.waffle-chart.bottom {
    transform: rotateY(180deg);
}

.waffle-chart.bottom.right {
    transform: rotateY(360deg);
}

.waffle-chart.top {
    transform: rotate(180deg);
}

.waffle-chart.top.right {
    transform: rotateX(180deg);
}

.waffle-chart-square {
    background: var(--bg-square, var(--bg-fallback-default));
    padding-top: 100%;
    border-radius: var(--radius-square);
    box-sizing: border-box;
    position: relative;
}

.waffle-chart-square.animate-fill {
    transition: background 0.3s ease-in-out;
}

.waffle-chart-square.zeroed:not(.a) {
    background: var(--bg-fallback-c);
}

.waffle-chart-square.a {
    background: var(--bg-square-a, var(--bg-fallback-a));
}

.waffle-chart-square.b {
    background: var(--bg-square-b, var(--bg-fallback-b));
}

.waffle-chart-square.c {
    background: var(--bg-square-c, var(--bg-fallback-c));
}

.waffle-chart-square:hover {
    filter: invert(15%) brightness(75%) saturate(300%);
}

.waffle-chart-display-container {
    margin: var(--gap);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.waffle-chart-display-container.bottom {
    flex-direction: column-reverse;
}

.total {
    margin: 0 calc(3 * var(--gap)) 0 0;
    /*display: flex;*/
    flex-direction: column;
    justify-content: center;
    font-size: var(--font-label);
    color: var(--bg-c, var(--bg-fallback-total));
    display: none;
}

.display-item h3 {
    font-size: var(--font-label);
    margin: 0 0 var(--gap) 0;
    line-height: 0.75;
}

.display-item p {
    font-size: var(--font-data);
    line-height: 1;
    margin: 0;
}