/* styles.css */
.SliderRoot {
    position: relative;
    display: flex;
    align-items: center;
}

.SliderRoot[data-orientation='vertical'] {
    flex-direction: column;
    width: 20px;
    /*height: 400px;*/
}

.SliderTrack {
    background-color: #f0f2f5;
    border-radius: 12px;
    flex-grow: 1;
    position: relative;
}

.SliderTrack[data-orientation='vertical'] {
    width: 3px;
}

.SliderRange {
    position: absolute;
    background-color: #5B949F;
}

.SliderRange[data-orientation='vertical'] {
    width: 100%;
}

.SliderThumb {
    display: block;
    width: 12px;
    height: 12px;
    background-color: white;
    border: 0.0625rem solid #5B949F;
    border-radius: 12px;
    box-shadow: 0rem 0.0625rem 0.8125rem 0rem rgba(0, 0, 0, 0.2);
}