.ckdet_website__cve,
.ckdet_website__ssl {
    display: flex;
    gap: 4px 2em;
    flex-wrap: wrap;
}

.ckdet_ip,
.ckdet_website__cve,
.ckdet_website__ssl,
.ckdet_ssl_on,
.email_leak_list,
.ckdet_email_leak_list {
    padding: 1em 4px;
    color: white;
    list-style: none;
}

.ckdet_website__cve_T,
.ckdet_website__cve_title,
.ckdet_website__ssl_title {
    font-size: 12px;
    font-weight: 500;
    opacity: .6;
}

.email_leak_list li,
.ckdet_email_leak_list li,
.ckdet_website__cve_B,
.ckdet_website__cve_block,
.ckdet_website__ssl_block,
.ckdet_website__cve_base,
.ckdet_website__cve_exploitability,
.ckdet_website__cve_id,
.ckdet_website__cve_impact {
    margin-bottom: .5em;
    background: hsl(0deg 0% 50% / 18%);
    padding: 8px 1em;
}

.ckdet_website__cve_V,
.ckdet_website__cve_value,
.ckdet_website__cve_base__value,
.ckdet_website__cve_exploitability__value,
.ckdet_website__cve_id__value,
.ckdet_ssl_ok__value,
.ckdet_website__cve_impact__value {
    font-weight: bold;
}

.ckdet_website__cve_link,
.ckdet_website__cve_link a {
    color: inherit !important;
    text-decoration: none;
}

.ckdet_website__cve_link {
    width: 100%;
}

.ckdet_website__cve_link a {
    text-decoration: none;
    border-radius: 5px;
    /*
    background-color: #459FE2;
    color: #FCFCFD;
    */
    background-color: #1C7170;
    color: #ffffff !important;
    height: 32px;
    padding: 4px 12px;
    font-size: 16px;
    font-weight: 400;
    text-transform: none;
    min-height: 32px;
    display: inline-block;
}

.ckdet_website__cve_link a:after {
    background-image: url('../../images/go.svg');
    content: "";
    display: inline-block;
    margin-left: 12px;
    width: 18px;
    height: 18px;
    background-size: contain;
    background-position: 0px 3px;
}

.ip_response__banner,
.ckdet_ip__banner,
.ckdet_ip_response__banner,
.email_leak_list .email_leak_list__email,
.ckdet_email_leak_list .ckdet_email_leak_list__email {
    display: inline-block;
    padding: 0.55em 0.9em;
    font-size: 0.75rem;
    font-weight: 700;
    max-width: 600px;
    text-align: left;
    word-break: break-all;
    line-height: 1;
    vertical-align: baseline;
    border: none;
    /*
    border-radius: 0.375rem;
    background: linear-gradient(195deg, #49a3f1, #0D918F);
    */
    background: hsl(0deg 0% 50% / 18%);
    color: #ffffff;
    position: relative;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    transform: none;
    margin-bottom: .25em;
}