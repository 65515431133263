div.oj-final {
    margin-top: 24px;
    margin-left: 54px;
    margin-right: 54px;
}

div.oj-signatory {
    margin-left: 267px;
    margin-right: 54px;
}

div.oj-text-l {
    font-size: 1em;
    text-align: justify;
    margin-top: 4px;
    margin-bottom: 4px;
    margin-left: 13px;
    border-bottom-color: black;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-top-color: black;
    border-top-style: solid;
    border-top-width: 1px;
}

hr.oj-doc-end {
    width: 20%;
    height: 1px;
    color: black;
    text-align: center;
    margin-top: 8px;
    margin-bottom: 8px;
}

hr.oj-doc-sep {
    width: 10%;
    height: 1px;
    color: black;
    text-align: center;
    margin-top: 8px;
    margin-bottom: 8px;
}

hr.oj-note {
    width: 20%;
    height: 1px;
    color: black;
    text-align: left;
    margin-top: 16px;
    margin-bottom: 4px;
}

hr.oj-separator {
    width: 100%;
    height: 1px;
    color: black;
    margin-top: 4px;
    margin-bottom: 4px;
}

p.oj-addr {
    text-align: justify;
    margin-top: 0;
    margin-bottom: 0;
}

p.oj-center {
    text-align: center;
    margin-top: 8px;
    margin-bottom: 0;
}

p.oj-doc-ti {
    font-weight: bold;
    text-align: center;
    margin-top: 16px;
    margin-bottom: 8px;
}

p.oj-edition {
    text-align: left;
    margin-top: 8px;
    margin-bottom: 8px;
}

p.oj-hd-date {
    text-align: left;
    margin-top: 8px;
    margin-bottom: 8px;
}

p.oj-hd-lg {
    text-align: center;
    margin-top: 8px;
    margin-bottom: 8px;
    border-style: solid;
    border-width: 1px;
    border-color: black;
}

p.oj-hd-oj {
    text-align: right;
    margin-top: 8px;
    margin-bottom: 8px;
}

p.oj-hd-ti {
    text-align: center;
    margin-top: 8px;
    margin-bottom: 8px;
}

p.oj-image {
    text-align: center;
    margin-top: 8px;
    margin-bottom: 8px;
}

p.oj-issn {
    font-size: 0.8em;
    text-align: right;
    margin-top: 16px;
    margin-bottom: 8px;
}

p.oj-lg {
    font-size: 2.4em;
    font-weight: bold;
    text-align: center;
    margin-top: 8px;
    margin-bottom: 8px;
    border-style: solid;
    border-width: 1px;
    border-color: black;
    padding: 13px;
}

p.oj-no-doc-c {
    text-align: center;
    margin-top: 8px;
    margin-bottom: 8px;
}

p.oj-normal {
    text-align: justify;
    margin-top: 8px;
    margin-bottom: 0;
}

p.oj-normal-center {
    text-align: center;
    margin-top: 8px;
    margin-bottom: 0;
}

p.oj-normal-right {
    text-align: right;
    margin-top: 8px;
    margin-bottom: 0;
}

p.oj-note {
    font-size: 0.8em;
    text-align: justify;
    margin-top: 4px;
    margin-bottom: 4px;
}

p.oj-separator {
    text-align: center;
    margin-top: 8px;
    margin-bottom: 8px;
}

p.oj-signatory {
    text-align: center;
    margin-top: 4px;
    margin-bottom: 4px;
}

p.oj-sti-art {
    font-weight: bold;
    text-align: center;
    margin-top: 4px;
    margin-bottom: 8px;
}

p.oj-tbl-cod {
    font-size: 0.9em;
    text-align: center;
    margin-right: 13px;
    margin-top: 4px;
    margin-bottom: 4px;
}

p.oj-tbl-hdr {
    font-size: 0.9em;
    font-weight: bold;
    text-align: center;
    margin-right: 13px;
    margin-top: 4px;
    margin-bottom: 4px;
}

p.oj-tbl-notcol {
    font-size: 0.9em;
    text-align: right;
    margin-top: 4px;
    margin-bottom: 4px;
}

p.oj-tbl-num {
    font-size: 0.9em;
    text-align: right;
    margin-right: 13px;
    margin-top: 4px;
    margin-bottom: 4px;
}

p.oj-tbl-txt {
    font-size: 0.9em;
    text-align: left;
    margin-top: 4px;
    margin-bottom: 4px;
}

p.oj-text-l {
    text-align: justify;
    margin-top: 4px;
    margin-bottom: 4px;
}

p.oj-ti-annotation {
    font-style: italic;
    text-align: left;
    margin-top: 8px;
    margin-bottom: 0;
}

p.oj-ti-art {
    font-style: italic;
    text-align: center;
    margin-top: 24px;
    margin-bottom: 8px;
}

p.oj-ti-coll {
    font-size: 1.2em;
    margin-top: 8px;
    margin-bottom: 8px;
}

p.oj-ti-doc-dur {
    font-weight: bold;
    text-align: justify;
    margin-top: 12px;
    margin-bottom: 8px;
}

p.oj-ti-doc-dur-assoc {
    font-weight: bold;
    text-align: justify;
    margin-top: 12px;
    margin-bottom: 8px;
}

p.oj-ti-doc-dur-num {
    font-weight: bold;
    text-align: left;
    margin-top: 12px;
    margin-bottom: 0;
}

p.oj-ti-doc-dur-star {
    font-weight: bold;
    text-align: center;
    margin-top: 12px;
    margin-bottom: 8px;
}

p.oj-ti-doc-eph {
    text-align: justify;
    margin-top: 12px;
    margin-bottom: 8px;
}

p.oj-ti-grseq-1 {
    font-weight: bold;
    text-align: justify;
    margin-top: 16px;
    margin-bottom: 8px;
}

p.oj-ti-grseq-toc {
    font-style: italic;
    text-align: center;
    margin-top: 16px;
    margin-bottom: 8px;
}

p.oj-ti-oj-1 {
    font-size: 2.4em;
    font-weight: bold;
    text-align: left;
    margin-top: 8px;
    margin-bottom: 0;
}

p.oj-ti-oj-2 {
    font-size: 1.6em;
    text-align: left;
    margin-top: 8px;
    margin-bottom: 8px;
}

p.oj-ti-oj-3 {
    font-size: 2.4em;
    font-weight: bold;
    text-align: right;
    margin-top: 8px;
    margin-bottom: 0;
}

p.oj-ti-sect-1-n {
    text-align: left;
    margin-top: 8px;
    margin-bottom: 8px;
}

p.oj-ti-sect-1-t {
    font-style: italic;
    text-align: left;
    margin-top: 8px;
    margin-bottom: 8px;
}

p.oj-ti-sect-2 {
    text-align: left;
    margin-top: 8px;
    margin-bottom: 8px;
}

p.oj-ti-section-1 {
    font-weight: bold;
    text-align: center;
    margin-top: 32px;
    margin-bottom: 0;
}

p.oj-ti-section-2 {
    font-weight: bold;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 8px;
}

p.oj-ti-tbl {
    text-align: center;
    margin-top: 8px;
    margin-bottom: 8px;
}

p.oj-year-date {
    font-weight: bold;
    text-align: right;
    margin-top: 8px;
    margin-bottom: 8px;
}

span.oj-bold {
    font-weight: bold;
}

span.oj-italic {
    font-style: italic;
}

span.oj-sp-normal {
    font-weight: bold;
    font-style: italic;
}

span.oj-sub {
    vertical-align: sub;
    font-size: 0.7em;
}

span.oj-super {
    vertical-align: super;
    font-size: 0.7em;
}

span.oj-stroke {
    text-decoration: line-through;
}

span.oj-underline {
    text-decoration: underline;
}

.oj-table {
    border-color: black;
    border-style: solid;
    border-width: 1px;
    border-spacing: 0;
}

/* Jira EURLEXNEW-576 */
.oj-ti-info {
    text-decoration: underline;
}

.oj-enumeration-spacing {
    margin-bottom: 1em;
}

.oj-quotation-ti {
    font-style: italic;
}

.oj-img {
    max-width:100%;
    height: auto;
}

.oj-formula {
    max-width:100%;
    height: auto;
}