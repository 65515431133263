.virtualized_checkbox_container {
    margin-top: 0;
    box-shadow: rgba(9, 181, 168, .015) 0 25px 25px;
    border-radius: 0;
    border-top: 0;
    /*border-bottom: 1px solid rgba(69, 159, 226, .3);*/
    border-bottom: 1px solid rgba(226, 226, 226, .5);
    border-left: 1px solid rgba(226, 226, 226, .75);
    border-right: 1px solid rgba(226, 226, 226, .5);

    border-image: linear-gradient(to top, rgba(69, 159, 226, .5), rgba(226, 226, 226, .15)) 1 100%;
    border-bottom: 1px solid rgba(69, 159, 226, .5);
    box-shadow: rgba(69, 159, 226, .5) 0px 1px 0;
}

.virtualized_checkbox_container input[type="text"] {
    width: auto;
    /*
    border-radius: 0.375rem;
    border-width: 1px;
    --tw-border-opacity: 1;
    --tw-bg-opacity: 1;
    border-color: rgb(212 212 216 / var(--tw-border-opacity));
    background-color: rgb(250 250 250 / var(--tw-bg-opacity));
    
    border-color: rgba(212, 212, 216, .8);
    background-color: rgba(250, 250, 250, .25);
    padding-top: 0.1375rem;
    padding-bottom: 0.1375rem;
    max-width: 320px;
    */

    background: transparent;
    outline: none;
    height: 40px;

    padding-left: 0.75rem;
    padding-right: 0.75rem;
    padding-top: 1rem;
    transform: translateY(-14px);
    padding-bottom: 0.25rem;
    font-size: .875rem;
    line-height: 1.25rem;
    --tw-text-opacity: 1;
    color: inherit;
}

.virtualized_checkbox_container input::placeholder {
    color: transparent;
}

.virtualized_checkbox_filter_label {
    margin-bottom: 0.25rem;
    display: block;
    width: 100%;
    font-size: 0.75rem;
    line-height: 1rem;
    --tw-text-opacity: 1;
    color: inherit;
}

.virtualized_checkbox_container label {
    font-size: 0.75rem;
    display: flex;
    align-items: end;
    height: 100%;
    gap: 8px;
    padding-left: 5px;
}

.virtualized_checkbox_container label input[type="checkbox"] {
    margin-bottom: 2px;
}

/*

.dark .virtualized_checkbox_container input[type="text"] {
    border-color: rgb(212 212 216 / var(--tw-border-opacity));
    background-color: rgb(250 250 250 / var(--tw-bg-opacity));
}


.dark .virtualized_checkbox_container #filter {
    --tw-border-opacity: 1;
    border-color: rgb(63 63 70 / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgb(9 9 11 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(244 244 245 / var(--tw-text-opacity));
}

.dark .virtualized_checkbox_filter_label {
    --tw-text-opacity: 1;
    color: rgb(228 228 231 / var(--tw-text-opacity));
    color: red !important;
}
*/