.bx--cc--grid rect.chart-grid-backdrop.stroked {
    fill: transparent
}

.bx--cc--scatter circle.dot.unfilled {
    fill: #d4bbff
}

.y-labels {
    opacity: 0;
}

/* radar s */
/*
hsla(356, 82%, 51%, 0.8) 12%, 
                                hsla(30, 92%, 54%, 0.8) 23.5%, 
                                hsla(51, 82%, 54%, 0.8) 33%, 
                                hsla(147, 86%, 34%, 0.8) 42%, 
                                hsla(206, 73%, 58%, 0.8) 47%, 
                                hsla(206, 73%, 58%, 0.8) 47%,
*/
.bx--cc--radar .y-axes *:nth-child(2) {
    stroke: #e81c2b;
    stroke: hsla(356, 82%, 51%, 0.5);
    fill: hsla(356, 82%, 51%, 0.25);
}

.bx--cc--radar .y-axes *:nth-child(3) {
    stroke: #f5881d;
    stroke: hsla(30, 92%, 54%, 0.5);
    fill: hsla(30, 92%, 54%, 0.15);
}

.bx--cc--radar .y-axes *:nth-child(4) {
    stroke: #eace2a;
    stroke: hsla(51, 82%, 54%, 0.5);
    fill: hsla(51, 82%, 54%, 0.1);
}

.bx--cc--radar .y-axes *:nth-child(5) {
    stroke: #0ca34f;
    stroke: hsla(147, 86%, 34%, 0.5);
    fill: hsla(147, 86%, 34%, 0.075);
}

.bx--cc--radar .y-axes *:nth-child(6) {
    stroke: #459fe2;
    stroke: hsla(206, 73%, 58%, 0.5);
    fill: hsla(206, 73%, 58%, 0.05);
}

/* radar e */